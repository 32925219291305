<template>
  <div id="cms-a-la-carte-create">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">À la Carte - Create</h2></b-col>
        <b-col></b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="col-sm-9">
          <div v-if="loading" class="text-center">
            <b-spinner variant="secondary" label="Loading"></b-spinner>
          </div>
          <div v-else class="col-sm-9" style="color: white; text-align: right">
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Category:"
            >
              <b-form-select
                      v-model="category['name']"
                      :options="categories"
                      value-field="name"
                      text-field="name"
              ></b-form-select>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Item name:"
                    label-for="item-name"
            >
              <b-form-input id="item-name" v-model="menuItem.item_name"></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Remark in English:"
                    label-for="remark-en"
            >
              <b-form-input id="remark-en" v-model="menuItem.remark_en"></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Remark in Dutch:"
                    label-for="remark-nl"
            >
              <b-form-input id="remark-nl" v-model="menuItem.remark_nl"></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Price:"
                    label-for="price"
            >
              <b-form-input id="price" v-model="menuItem.price"></b-form-input>
            </b-form-group>
            <router-link class="link-color" :to="{ name: 'CmsALaCarte' }" @click.prevent>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </router-link>
            <b-button  @click.prevent="saveMenuItem()" type="submit" variant="success">Submit</b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import SideMenu from '@/components/SideMenu.vue'
  import axios from "axios";

  export default {
    data() {
      return {
        loading: true,
        menuItem: [],
        category: [],
        categories: []
      }
    },
    components: {
      SideMenu
    },
    beforeCreate () {
      this.axios
        .get('https://batavia-backend.herokuapp.com/api/category')
        .then(response => {
          this.categories = response.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    methods: {
      saveMenuItem() {
        this.axios.patch(
            `https://batavia-backend.herokuapp.com/api/menu-item/${this.$route.params.id}`,
            {
              category_id: this.getCategoryId(),
              item_name: this.menuItem.item_name,
              remark_en: this.menuItem.remark_en,
              remark_nl: this.menuItem.remark_nl,
              price: this.menuItem.price
            },
            {
              headers: {
                Authorization: axios.defaults.headers.common.Authorization
              }
            }
        )
        .then(() => {
          this.$router.push({ name: 'CmsALaCarte' })
        })
      },

      getCategoryId() {
        let categoryName = this.category['name']
        let categoryId = this.category['id']

        this.categories.map(function (category) {
          if (category['name'] === categoryName) {
            categoryId = category['id'];
          }
        })

        return categoryId
      }
    }
  }
</script>